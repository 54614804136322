import {
  IContactMethodType,
  ICountry,
  ILevel,
  IOkeeyCashAccountNumberStatus,
  IPhoneNumberCountry,
  IState,
  ITimezone,
  IUser,
  IUserLanguage,
  IUserParent,
  IUserType
} from './user.interface';
import { IAvatar } from './avatar.interface';
import { environment } from '../../../environments/environment';
import { from, Observable, of, switchMap } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { format } from 'date-fns';

interface IBalance {
  current?: number;
  potential?: number;
  earned?: number;
  spent?: number;
}

interface IUserLevel {
  id: number;
  leaves: number;
  start: number;
  end: number;
}

export enum UserTypeClasses {
  GANATRONS = 'ganatrons',
  GANASOCIOS = 'ganasocios',
  GANALIADOS = 'ganaliados',
  GANAGENTES = 'ganagentes',
  GANAQUIPOS = 'ganaquipos',
  GANARBOLS = 'ganarbols',
  GANACUATES = 'ganacuates',
  SEED = 'seed'
}

export enum UserTypes {
  /** Ganatron */
  PA = 'PA',
  /** Ganasocio */
  PR = 'PR',
  /** Ganaliados */
  AY = 'AY',
  /** Ganagente */
  CO = 'CO',
  /** Ganaquipo */
  RE = 'RE',
  /** Ganarbol */
  MU = 'MU',
  /** Ganacuate */
  MX = 'MX',
  /** Seed */
  SD = 'SD'
}

export class User {
  public get id(): number {
    return this._id;
  }
  public get userObj(): IUser {
    return this._userObj;
  }
  public get fullName(): string {
    return this.firstName + ' ' + this._lastName;
  }

  public get firstName(): string {
    return this._firstName;
  }

  public get contract_date(): string {
    return this._contractDate;
  }

  public get contact_person(): string {
    return this._contactPerson;
  }

  public get contract_terms(): string {
    return this._contractTerms;
  }

  public get userParent(): IUserParent {
    return this._userParent;
  }

  public get userPartner(): IUserParent {
    return this._userPartner;
  }

  public get userCordinator(): IUserParent {
    return this._userCordinator;
  }

  public get userRecruiter(): IUserParent {
    return this._userRecruiter;
  }

  public get lastName(): string {
    return this._lastName;
  }

  public get userTypeCode(): string {
    return this._userType?.code || '';
  }

  public get userTypeClass(): UserTypeClasses {
    const userTypeClassMap: { [key in UserTypes]: UserTypeClasses } = {
      [UserTypes.PA]: UserTypeClasses.GANATRONS,
      [UserTypes.PR]: UserTypeClasses.GANASOCIOS,
      [UserTypes.AY]: UserTypeClasses.GANALIADOS,
      [UserTypes.CO]: UserTypeClasses.GANAGENTES,
      [UserTypes.RE]: UserTypeClasses.GANAQUIPOS,
      [UserTypes.MU]: UserTypeClasses.GANARBOLS,
      [UserTypes.MX]: UserTypeClasses.GANACUATES,
      [UserTypes.SD]: UserTypeClasses.SEED
    };
    return userTypeClassMap[this.userTypeCode] || '';
  }

  public get isSpecial(): boolean {
    const specialTypes: UserTypes[] = [UserTypes.PR, UserTypes.CO, UserTypes.RE, UserTypes.PA, UserTypes.AY];
    return specialTypes.includes(this._userType?.code as UserTypes);
  }

  public get userType(): UserTypes {
    return this._userType?.code as UserTypes;
  }

  /** Is user's type Ganasocio? */
  public get isGanasocio(): boolean {
    return this.userType === UserTypes.PR;
  }

  public get initials(): string {
    const firstNameInitial = this.firstName.charAt(0).toUpperCase();
    const lastNameInitial = this.lastName.charAt(0).toUpperCase();
    return `${firstNameInitial}${lastNameInitial}`;
  }

  public get profileColor(): string {
    return this._profileColor;
  }

  public get totalNetworkSize(): number {
    return this._totalNetworkSize;
  }

  public get directRecruits(): number {
    return this._directRecruits;
  }

  public get branches(): number {
    return this._branches;
  }

  public get moneyBalance(): number {
    return this._moneyBalance;
  }

  public get spentMoney(): number {
    return +this._spentMoney || 0;
  }

  public get isRewardBased(): boolean {
    return !!this._rewardBased;
  }

  public get currentCommission(): number {
    return +this._currentCommission || 0;
  }

  public get pendingPayments(): number {
    return +this._pendingPayments || 0;
  }

  public get paidEarnings(): number {
    return +this._paidEarnings || 0;
  }

  public get okeeyCashAccountNumber(): string {
    return this._okeeyCashAccountNumber;
  }

  public get avatar(): IAvatar {
    return this._avatar;
  }
  public get avatarUrl(): string {
    return environment.avatarsUrl + 'Property_1=' + this.avatar?.image;
  }

  public get userLanguageCode(): string {
    return this._userLanguage?.code || 'es';
  }

  public get referralCode(): string {
    return this._referralCode;
  }

  public get ganamigosSince(): string {
    return this._ganamigosSince;
  }
  public get formattedGanamigosSince(): string {
    return format(new Date(this._ganamigosSince), 'dd/MM/yyyy');
  }

  public get email(): string {
    return this._email;
  }
  public get phoneNumber(): string {
    return this._phoneNumber;
  }

  public get address(): string {
    return [this._city, this._state?.code].filter(Boolean).join(', ');
  }

  public get zipCode(): string {
    return this?._zipCode;
  }

  public get fullPhoneNumber(): string {
    return this._phoneNumberCountry?.phone_code + this._phoneNumber;
  }
  public get phoneNumberCountryCode(): string {
    return this._phoneNumberCountry?.phone_code;
  }

  public get isVisible(): boolean {
    return this._isVisible;
  }

  public get levelNumber(): number {
    return this._level?.id || 1;
  }

  public get streetAddress(): string {
    return this._address;
  }

  public get isEligible(): boolean {
    return this._isEligible;
  }

  public get okeeyCashInternalUserId(): number {
    return this._okeeyCashInternalUserId;
  }

  public get okeeyCashAccountNumberStatusCode(): string {
    return this._okeeyCashAccountNumberStatus?.code;
  }

  public get totalGanarbols(): number {
    return this._totalGanarbols || 0;
  }

  public get totalGanacuates(): number {
    return this._totalGanacuates || 0;
  }

  public get bankedEligibility(): number {
    return this._bankedEligibility || 0;
  }

  public get isUsMember(): boolean {
    return this._userType?.code !== UserTypes.MX;
  }

  public get typeName(): string {
    return this._userType?.name || '';
  }

  public get balance(): IBalance {
    return {
      current: +this._balance,
      potential: +this._potentialBalance,
      earned: +this._totalEarnedBalance,
      spent: +this._totalSpentBalance
    };
  }

  public get level(): IUserLevel {
    return {
      id: this.levelNumber,
      leaves: this._leaves,
      start: +this.start_balance,
      end: +this.end_balance
    };
  }

  public get leaves(): number {
    return this._leaves;
  }

  public get rings(): number {
    return this._rings;
  }

  // Main Info
  private readonly _id?: number;
  private readonly _avatar?: IAvatar;
  private readonly _firstName?: string;
  private readonly _lastName?: string;
  private readonly _contractTerms: string;
  private readonly _contractDate?: string | null;
  private readonly _contactPerson?: string | null;
  private readonly _email?: string;
  private readonly _userObj: IUser;
  private readonly _userLanguage: IUserLanguage;
  private readonly _okeeyCashAccountNumber: string;
  private readonly _okeeyCashAccountNumberStatus: IOkeeyCashAccountNumberStatus;
  private readonly _profileColor: string;

  // Personal Info
  private readonly _state?: IState;
  private readonly _city?: string | null;
  private readonly _gender?: string | null;
  private readonly _phoneNumberCountry?: IPhoneNumberCountry;
  private readonly _phoneNumber?: string;
  private readonly _fullName?: string | null;
  private readonly _timezone?: ITimezone;
  private readonly _country?: ICountry;
  private readonly _contactMethodType?: IContactMethodType;
  private readonly _referralCode?: string;
  private readonly _streetAddress?: string;
  private readonly _contract_documentation_en?: string | null;
  private readonly _contract_documentation_es?: string | null;
  private readonly _userParent: IUserParent;
  private readonly _userPartner: IUserParent;
  private readonly _userRecruiter: IUserParent;
  private readonly _userCordinator: IUserParent;
  private readonly _address?: string;
  private readonly _zipCode?: string;
  private readonly _totalNetworkSize?: number;
  private readonly _directRecruits?: number;
  private readonly _moneyBalance?: number;

  // User Settings and Status
  private readonly _userType?: IUserType;
  private readonly _level?: ILevel;
  private readonly _isVisible?: boolean;
  private readonly _isEligible?: boolean;
  private readonly _okeeyCashInternalUserId?: number;
  private readonly _totalGanarbols?: number;
  private readonly _totalGanacuates?: number;
  private readonly _bankedEligibility?: number;
  private readonly _status?: string;
  private readonly _branches?: number;
  private readonly _leaves?: number;
  private readonly _rings?: number;
  private readonly end_balance: string;
  private readonly start_balance: string;
  private readonly _ganamigosSince: string;

  // Balance
  private readonly _balance?: string;
  private readonly _potentialBalance?: string;
  private readonly _totalEarnedBalance?: string;
  private readonly _totalSpentBalance?: string;
  private readonly _totalReferrals?: number;
  private readonly _spentMoney?: number;
  private readonly _rewardBased?: boolean;
  private readonly _currentCommission?: number;
  private readonly _pendingPayments?: number;
  private readonly _paidEarnings?: number;

  private _reloadFn: () => Observable<User>;

  constructor(user: IUser) {
    this._userObj = user;
    this._id = user.id;
    this._email = user.email;
    this._state = user.state;
    // this._state = user.state;
    this._city = user.city;
    this._gender = user.gender;
    this._phoneNumberCountry = user.phone_number_country;
    this._phoneNumber = user.phone_number;
    this._avatar = user.avatar;
    this._firstName = user.first_name;
    this._lastName = user.last_name;
    this._fullName = user.full_name;
    this._contractTerms = user.contract_terms;
    this._contractDate = user.contract_date;
    this._contactPerson = user.contact_person;
    this._userParent = user.user_parent;
    this._userPartner = user.user_partner;
    this._userRecruiter = user.user_recruiter;
    this._userCordinator = user.user_coordinator;
    this._timezone = user.timezone;
    this._country = user.country;
    this._contactMethodType = user.contact_method_type;
    this._balance = user.balance;
    this._potentialBalance = user.potential_balance;
    this._totalEarnedBalance = user.total_earned_balance;
    this._totalSpentBalance = user.total_spent_balance;
    this._referralCode = user.referral_code;
    this._userType = user.user_type;
    this._level = user.level;
    this._isVisible = user.is_visible;
    this._isEligible = user.is_eligible;
    this._okeeyCashInternalUserId = user?.okeey_cash_internal_user_id;
    this._totalReferrals = +user?.total_referrals;
    this._spentMoney = user?.spent_money;
    this._totalGanarbols = user.total_gana_arboles;
    this._totalGanacuates = user.total_gana_cuates;
    this._bankedEligibility = user.banked_eligibility;
    this._ganamigosSince = user.ganamigos_since;
    this._status = user.status;
    this._branches = user.branches;
    this._leaves = user.leaves;
    this._rings = user.rings;
    this._userLanguage = user.user_language;
    this.start_balance = user?.level?.start_balance;
    this.end_balance = user?.level?.end_balance;
    this._okeeyCashAccountNumber = user?.okeey_cash_account_number;
    this._okeeyCashAccountNumberStatus = user?.okeey_cash_account_number_status;
    this._address = user?.address;
    this._zipCode = user?.zip_code;
    this._profileColor = user?.profile_color;
    this._rewardBased = !!user?.reward_based;
    this._currentCommission = user?.current_commission;
    this._pendingPayments = user?.pending_payments;
    this._paidEarnings = user?.paid_earnings;
    this._totalNetworkSize = user?.total_network_size;
    this._directRecruits = user?.direct_recruits;
    this._branches = user?.branches;
    this._moneyBalance = +user?.money_balance;
  }

  public copyReferralCode(): Observable<boolean> {
    const text: string = this.referralCode;
    if (navigator?.clipboard && navigator?.clipboard?.writeText) {
      return from(navigator?.clipboard?.writeText(text)).pipe(
        switchMap(() => of(true)),
        catchError(() => of(false))
      );
    } else {
      return this.fallbackCopyTextToClipboard(text);
    }
  }

  private fallbackCopyTextToClipboard(text: string): Observable<boolean> {
    const textarea: HTMLTextAreaElement = this.createTextArea(text);
    document.body.appendChild(textarea);
    return of(this.createTextArea(text)).pipe(
      switchMap(textarea => {
        document.body.appendChild(textarea);
        textarea.select();
        const success: boolean = document.execCommand('copy');
        return of(success);
      }),
      catchError(error => {
        return of(false);
      }),
      finalize(() => {
        document.body.removeChild(textarea);
      })
    );
  }

  private createTextArea(text: string): HTMLTextAreaElement {
    const textarea: HTMLTextAreaElement = document.createElement('textarea');
    textarea.value = text;
    textarea.style.position = 'fixed';
    textarea.style.opacity = '0';
    return textarea;
  }
}
