import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IPagination } from '../types/pagination.interface';
import { NetworkError } from '../models/network-error';
import { IFilter } from '../types/filter.interface';
import { ISorting } from '../types/sorting.interface';
import { KorviuNotificationService } from './korviu-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { SortOrders } from '../types/sort-order.enum';
import { IGetPaginatedResponse } from '../types/get-paginated-response.interface';

@Injectable({
  providedIn: 'root'
})
export class BaseApiService {
  public endpoint: string;

  constructor(
    public http: HttpClient,
    public korviuNotificationService: KorviuNotificationService,
    public translateService: TranslateService
  ) {
    this.endpoint = '';
  }

  public get(
    endpoint: string,
    pagination?: IPagination,
    filters?: IFilter[],
    sorting?: ISorting,
    customParams?: IFilter[]
  ): Observable<any> {
    let params: HttpParams = new HttpParams();
    if (customParams) {
      customParams.forEach((p: IFilter) => {
        params = params.append(p.name, p.value);
      });
    }
    if (pagination) {
      params = params.append('page_size', pagination.pageSize).append('page', pagination.page);
    }
    if (filters?.length) {
      params = this._getParamsWithFilters(filters, params);
    }
    if (sorting?.sortBy && sorting?.sortOrder) {
      let ordering: string = sorting.sortBy;
      if (sorting?.sortOrder === SortOrders.DESCEND) {
        ordering = '-' + ordering;
      }
      params = params.append('ordering', ordering);
    }
    return this.http.get<any>(environment.apiUrl + endpoint, { params });
  }

  public put(endpoint: string, body: any): Observable<any> {
    return this.http.put<any>(environment.apiUrl + endpoint, this._prepareBody(body));
  }

  public patch(endpoint: string, body: any): Observable<any> {
    return this.http.patch<any>(environment.apiUrl + endpoint, this._prepareBody(body));
  }

  public post(endpoint: string, body: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + endpoint, this._prepareBody(body));
  }

  public delete(endpoint: string, body?: any): Observable<any> {
    const options: any = {};
    if (body) {
      options.body = body;
    }
    return this.http.delete<any>(environment.apiUrl + endpoint, options);
  }

  public handleError(error: NetworkError, errorCodesForNotification?: number[]): Observable<never> {
    if (errorCodesForNotification?.includes(error?.code)) {
      if (error?.errorPayload?.messageCode) {
        this.korviuNotificationService.showLocalizedError(error?.errorPayload?.messageCode);
      }
    }
    return throwError(error);
  }

  public returnEmptyPaginatedResponse(): Observable<IGetPaginatedResponse<any[]>> {
    return of({
      links: { next: '', previous: '' },
      count: 0,
      results: [],
      per_page: 0,
      total_pages: 0
    });
  }

  private _getParamsWithFilters(filters: IFilter[], params: HttpParams): HttpParams {
    const groupedParams: { [key: string]: string[] } = {};

    filters.forEach((param: IFilter) => {
      if (!groupedParams[param.name]) {
        groupedParams[param.name] = [];
      }
      groupedParams[param.name].push(param.value);
    });

    for (let key in groupedParams) {
      params = params.append(key, groupedParams[key].join(','));
    }

    return params;
  }

  // This function is necessary to prevent the Django error related to empty strings
  private _prepareBody(body: object): object {
    let preparedBody: object = body;
    for (let key in preparedBody) {
      preparedBody[key] = this._getValueOrNull(preparedBody[key]);
    }
    return preparedBody;
  }

  private _getValueOrNull(value: any): any {
    if (typeof value === 'number' || typeof value === 'boolean') {
      return value;
    }
    if (!!value) {
      return value;
    }
    return null;
  }
}
