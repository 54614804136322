import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

import { LocalStorageKeys } from '../types/local-storage-keys.enum';
import { BaseApiService } from './base-api.service';
import { ILogInData } from '../types/log-in-data.interface';
import { IUser } from '../types/user.interface';
import { ISignUpInfo } from '../types/sign-up-info.interface';
import { ILogInResponse } from '../types/log-in-response.interface';
import { NetworkError } from '../models/network-error';
import { KorviuNotificationService } from './korviu-notification.service';
import { SignUpMode } from '../../auth/core/types/sign-up-modes.enum';
import { IAvatar } from '../types/avatar.interface';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../types/user.model';
import { SignUpService } from '../../auth/core/sign-up.service';

interface IGanamigoResponse {
  name: string;
  phone_code: string;
  phone_number: string;
}

interface ISignUpSuccessResponse {
  newUserId: number;
  message: string;
  messageCode: string;
  newReferalCode: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseApiService {
  public get isAuthenticated(): boolean {
    return !!this.userToken;
  }

  private _userToken: string;
  public get userToken(): string {
    return this._userToken || localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  }
  public set userToken(value: string) {
    localStorage.setItem(LocalStorageKeys.USER_TOKEN, value);
    this._userToken = value;
  }

  private _currentUser: User;
  public get currentUser(): User {
    return this._currentUser;
  }
  public set currentUser(value: User) {
    this._currentUser = value;
  }

  private readonly _recoverEndpoint: string;
  private readonly _registerEndpoint: string;

  constructor(
    private _router: Router,
    private _signUpService: SignUpService,
    http: HttpClient,
    korviuNotificationService: KorviuNotificationService,
    translateService: TranslateService
  ) {
    super(http, korviuNotificationService, translateService);
    this.endpoint = 'users/';
    this._recoverEndpoint = 'users/recover/';
    this._registerEndpoint = 'users/register/';
    this._currentUser = null;
  }

  public verifyGanamigo(data: any): Observable<IGanamigoResponse> {
    return this.post(this.endpoint + 'verify-ganamigo/', data).pipe(
      catchError((error: NetworkError) => this.handleError(error, [401]))
    );
  }

  public addGanamigo(data: any): Observable<IGanamigoResponse> {
    return this.post(this.endpoint + 'add-ganamigo/', data).pipe(
      catchError((error: NetworkError) => this.handleError(error, [401]))
    );
  }

  public getAvatarsList(): Observable<IAvatar[]> {
    return this.get(this.endpoint + 'avatars/list');
  }

  public signUp(signUpInfo: ISignUpInfo, signUpMode?: SignUpMode): Observable<string> {
    let createAccountEndpoint: string = this._registerEndpoint + 'createAccount';
    const live_in_us: boolean = signUpMode === SignUpMode.US;
    let createAccountPayload: any = {
      user_info: { ...signUpInfo.user_info },
      contact_info: { ...signUpInfo.contact_info },
      referral_code: signUpInfo.referral_code,
      lang: this.translateService.currentLang,
      live_in_us
    };
    if (signUpMode === SignUpMode.PARTNER) {
      createAccountEndpoint = 'partner-organization/register/';
      createAccountPayload = this._getPayloadWithoutEmptyData({ ...signUpInfo.user_info, ...signUpInfo.contact_info });
    }

    let signUpSuccessResponse: ISignUpSuccessResponse;
    return this.post(createAccountEndpoint, createAccountPayload).pipe(
      switchMap((response: ISignUpSuccessResponse) => {
        signUpSuccessResponse = response;
        return this._signUpService.invitationId
          ? this.acceptInvitation(
              this._signUpService.invitationId,
              response.newUserId,
              this._signUpService.userTypeCode
            )
          : of(null);
      }),
      tap(() => {
        if (signUpMode === SignUpMode.MEXICAN || signUpMode === SignUpMode.US) {
          this._signUpService.step++;
          this._signUpService.newUserReferralCode = signUpSuccessResponse.newReferalCode;
        }
        if (signUpMode === SignUpMode.PARTNER) {
          this._router.navigate(['auth', 'sign-up', 'thanks']);
        }
      }),
      catchError((error: NetworkError) => this.handleError(error, [400, 404]))
    );
  }

  public acceptInvitation(invitation_id: number, user_id: number, user_type_code: string): Observable<string> {
    return this.post(`invitations/accept/${invitation_id}/${user_id}/${user_type_code}/`, {}).pipe(
      catchError(() => of(null))
    );
  }

  public sendLogInRequest(logInData: ILogInData): Observable<string> {
    const { username, password } = logInData;
    return this.post(this.endpoint + 'token', { username, password }).pipe(
      map((response: ILogInResponse) => response.token),
      catchError((error: NetworkError) => this.handleError(error, [400, 403, 404, 423]))
    );
  }

  public checkOkeeyCashAccountUsage(okeey_cash_account_number: string): Observable<string> {
    return this.post(this.endpoint + 'okeey-cash-account-number-in-use/', { okeey_cash_account_number });
  }

  public saveOkeeyCashAccount(okeey_cash_account_number: string): Observable<string> {
    return this.post(this.endpoint + 'okeey-cash-update/', { okeey_cash_account_number });
  }

  public removeOkeeyCashAccount(): Observable<string> {
    return this.delete(this.endpoint + 'okeey-cash-update/');
  }

  public verifyEmailUsage(email: string): Observable<string> {
    return this.post(this._registerEndpoint + 'verifyEmailUsage', { email });
  }

  public requestRecovery(email: string, language: string): Observable<any> {
    return this.post(this._recoverEndpoint + 'requestRecovery', { email, language });
  }

  public updatePassword(token: string, password: string): Observable<string> {
    return this.post('users/recover/updatePassword', { token, password });
  }

  public verifyPhoneUsage(phone_code: string, phone_number: string): Observable<string> {
    return this.post(this.endpoint + 'phone-in-use/', { phone_number, phone_code });
  }

  public getMe(reload?: boolean): Observable<User> {
    return this.currentUser && !reload
      ? of(this.currentUser)
      : this.get(this.endpoint + 'me').pipe(
          map((userObj: IUser) => {
            const user: User = new User(userObj);
            this.currentUser = user;
            return user;
          }),
          catchError((error: NetworkError) => this.handleError(error, [401, 403]))
        );
  }

  public updateMe(me: IUser): Observable<string> {
    return this.patch(this.endpoint + 'me', me).pipe(
      catchError((error: NetworkError) => this.handleError(error, [401, 403]))
    );
  }

  public logOut(): void {
    of(this._router.navigate(['/', 'auth', 'log-in']))
      .pipe(
        tap(() => {
          this.userToken = null;
          this.currentUser = null;
          localStorage.removeItem(LocalStorageKeys.USER_TOKEN);
        }),
        take(1)
      )
      .subscribe();
  }

  public asyncLogin(token: string, email: string, isRememberMe?: boolean): Observable<any> {
    this.userToken = token;
    this._rememberMe(email, isRememberMe);
    return from(this._router.navigate(['/workspace']));
  }

  public deleteAccount(password: string): Observable<string> {
    return this.delete(this.endpoint + 'me', { password }).pipe(tap(() => this.logOut()));
  }

  private _rememberMe(email: string, isRememberMe?: boolean): void {
    const existingEmail: string = localStorage.getItem(LocalStorageKeys.EMAIL);

    if (isRememberMe) {
      localStorage.setItem(LocalStorageKeys.EMAIL, email);
    } else if (existingEmail === email) {
      localStorage.removeItem(LocalStorageKeys.EMAIL);
    }
  }

  private _getPayloadWithoutEmptyData(payload: object): object {
    for (let key in payload) {
      if (this.isEmpty(payload[key])) {
        delete payload?.[key];
      }
    }

    return payload;
  }

  private isEmpty(value: any): boolean {
    return !(typeof value === 'number' || typeof value === 'boolean' || !!value);
  }
}
