import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';

@Component({
  selector: 'korviu-button',
  standalone: true,
  imports: [NzButtonModule, NzIconModule, CommonModule, RouterModule],
  templateUrl: './korviu-button.component.html',
  styleUrls: ['./korviu-button.component.scss']
})
export class KorviuButtonComponent {
  @Input() public disabled: boolean = false;
  @Input() public type: 'button' | 'submit' | 'reset' | 'link' = 'button';
  @Input() public link: string = '';
  @Input() public color: 'primary' | 'ghost' | 'tertiary' | 'warning' = 'primary';
  @Input() public size: 'x-small' | 'small' | 'medium' | 'large' = 'medium';
  @Input() public icon: 'share' | 'plus' | 'upload' | 'balance' | 'redeem' | 'wallet' = null;
  @Output() public onclick: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  public onButtonClick(): void {
    this.onclick.emit();
  }
}
