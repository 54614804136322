<button
  *ngIf="type !== 'link'"
  nz-button
  class="button paragraph-medium"
  [disabled]="disabled"
  [type]="type"
  [ngClass]="{
    primary: color === 'primary',
    ghost: color === 'ghost',
    tertiary: color === 'tertiary',
    warning: color === 'warning',
    'x-small-button': size === 'x-small',
    'small-button': size === 'small',
    'medium-button': size === 'medium',
    'large-button': size === 'large'
  }"
  (click)="onButtonClick()"
>
  <i *ngIf="icon === 'wallet'" nz-icon>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M15.5 14.25C15.5 13.8358 15.8358 13.5 16.25 13.5H18.25C18.6642 13.5 19 13.8358 19 14.25C19 14.6642 18.6642 15 18.25 15H16.25C15.8358 15 15.5 14.6642 15.5 14.25ZM3 5.5H3.01373C3.13809 4.37501 4.09186 3.5 5.25 3.5H16.75C17.9926 3.5 19 4.50736 19 5.75V6.58697C20.4333 6.92555 21.5 8.21321 21.5 9.75V18.25C21.5 20.0449 20.0449 21.5 18.25 21.5H6.25C4.45508 21.5 3 20.0449 3 18.25V5.5ZM18.25 8H4.5V18.25C4.5 19.2165 5.2835 20 6.25 20H18.25C19.2165 20 20 19.2165 20 18.25V9.75C20 8.7835 19.2165 8 18.25 8ZM17.5 6.5V5.75C17.5 5.33579 17.1642 5 16.75 5H5.25C4.83579 5 4.5 5.33579 4.5 5.75C4.5 6.16421 4.83579 6.5 5.25 6.5H17.5Z"
        fill="#494F57"
      />
    </svg>
  </i>
  <i *ngIf="icon === 'share'" nz-icon>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M17 2C18.934 2 20.5018 3.56779 20.5018 5.50177C20.5018 7.43574 18.934 9.00353 17 9.00353C15.97 9.00353 15.0439 8.55887 14.4032 7.85104L9.84455 10.4555C9.94786 10.7859 10.0035 11.1374 10.0035 11.5017C10.0035 11.8663 9.94786 12.2176 9.84455 12.548L14.4023 15.1536C15.043 14.4451 15.9695 14 17 14C18.934 14 20.5018 15.5677 20.5018 17.5017C20.5018 19.4357 18.934 21.0035 17 21.0035C15.066 21.0035 13.4982 19.4357 13.4982 17.5017C13.4982 17.1374 13.5539 16.7859 13.6572 16.4555L9.09951 13.85C8.45873 14.5584 7.53223 15.0036 6.50177 15.0036C4.5678 15.0036 3 13.4358 3 11.5017C3 9.56775 4.5678 8 6.50177 8C7.53173 8 8.45784 8.44466 9.09859 9.15249L13.6572 6.54807C13.5539 6.21766 13.4982 5.86622 13.4982 5.50177C13.4982 3.56779 15.066 2 17 2ZM17 15.5C15.8945 15.5 14.9982 16.3962 14.9982 17.5017C14.9982 18.6073 15.8945 19.5035 17 19.5035C18.1055 19.5035 19.0018 18.6073 19.0018 17.5017C19.0018 16.3962 18.1055 15.5 17 15.5ZM6.50177 9.5C5.39622 9.5 4.5 10.3962 4.5 11.5017C4.5 12.6074 5.39622 13.5036 6.50177 13.5036C7.60731 13.5036 8.50354 12.6074 8.50354 11.5017C8.50354 10.3962 7.60731 9.5 6.50177 9.5ZM17 3.5C15.8945 3.5 14.9982 4.39622 14.9982 5.50177C14.9982 6.60731 15.8945 7.50353 17 7.50353C18.1055 7.50353 19.0018 6.60731 19.0018 5.50177C19.0018 4.39622 18.1055 3.5 17 3.5Z"
        fill="#494F57"
      />
    </svg>
  </i>
  <i *ngIf="icon === 'plus'" nz-icon>
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path
        d="M12.2476 3.5C12.6273 3.5 12.9412 3.78201 12.9909 4.14808L12.9978 4.24985L12.999 11.5H20.2521C20.6663 11.5 21.0021 11.8358 21.0021 12.25C21.0021 12.6297 20.7199 12.9435 20.3539 12.9932L20.2521 13H12.999L13.001 20.2491C13.0011 20.6633 12.6654 20.9993 12.2512 20.9993C11.8715 20.9993 11.5576 20.7173 11.5079 20.3512L11.501 20.2494L11.499 13H4.25C3.83578 13 3.5 12.6642 3.5 12.25C3.5 11.8703 3.78215 11.5565 4.14823 11.5068L4.25 11.5H11.499L11.4978 4.25015C11.4977 3.83594 11.8334 3.5 12.2476 3.5Z"
        fill="#FCFCFC"
      />
    </svg>
  </i>
  <i *ngIf="icon === 'upload'" nz-icon>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M6.25 4.96466H7.40469V10.2506C7.40469 10.3193 7.46094 10.3756 7.52969 10.3756H8.46719C8.53594 10.3756 8.59219 10.3193 8.59219 10.2506V4.96466H9.75C9.85469 4.96466 9.9125 4.84435 9.84844 4.7631L8.09844 2.54747C8.08674 2.53253 8.0718 2.52045 8.05475 2.51214C8.03769 2.50383 8.01897 2.49951 8 2.49951C7.98103 2.49951 7.96231 2.50383 7.94525 2.51214C7.92819 2.52045 7.91326 2.53253 7.90156 2.54747L6.15156 4.76154C6.0875 4.84435 6.14531 4.96466 6.25 4.96466ZM13.7188 9.78185H12.7812C12.7125 9.78185 12.6562 9.8381 12.6562 9.90685V12.3131H3.34375V9.90685C3.34375 9.8381 3.2875 9.78185 3.21875 9.78185H2.28125C2.2125 9.78185 2.15625 9.8381 2.15625 9.90685V13.0006C2.15625 13.2772 2.37969 13.5006 2.65625 13.5006H13.3438C13.6203 13.5006 13.8438 13.2772 13.8438 13.0006V9.90685C13.8438 9.8381 13.7875 9.78185 13.7188 9.78185Z"
      />
    </svg>
  </i>
  <i *ngIf="icon === 'balance'" nz-icon>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M18.25 2.99609C19.7688 2.99609 21 4.22731 21 5.74609V18.2461C21 19.7649 19.7688 20.9961 18.25 20.9961H5.75C4.23122 20.9961 3 19.7649 3 18.2461V5.74609C3 4.22731 4.23122 2.99609 5.75 2.99609H18.25ZM18.25 4.49609H5.75C5.05964 4.49609 4.5 5.05574 4.5 5.74609V18.2461C4.5 18.9364 5.05964 19.4961 5.75 19.4961H18.25C18.9404 19.4961 19.5 18.9364 19.5 18.2461V5.74609C19.5 5.05574 18.9404 4.49609 18.25 4.49609ZM7.75 8.9994C8.1297 8.9994 8.44349 9.28034 8.49315 9.64485L8.5 9.74618V16.2526C8.5 16.6651 8.16421 16.9994 7.75 16.9994C7.3703 16.9994 7.05651 16.7185 7.00685 16.354L7 16.2526V9.74618C7 9.33374 7.33579 8.9994 7.75 8.9994ZM16.25 6.9994C16.6297 6.9994 16.9435 7.27381 16.9932 7.62983L17 7.72881V16.27C17 16.6728 16.6642 16.9994 16.25 16.9994C15.8703 16.9994 15.5565 16.725 15.5068 16.369L15.5 16.27V7.72881C15.5 7.32597 15.8358 6.9994 16.25 6.9994ZM11.9751 11.9961C12.3458 11.9925 12.6549 12.2628 12.707 12.6168L12.7146 12.7153L12.75 16.2626C12.754 16.6637 12.4293 16.9921 12.0249 16.9962C11.6542 16.9997 11.3451 16.7295 11.293 16.3755L11.2854 16.277L11.25 12.7296C11.246 12.3285 11.5707 12.0001 11.9751 11.9961Z"
        fill="#FCFCFC"
      />
    </svg>
  </i>
  <i *ngIf="icon === 'redeem'" nz-icon>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M14.5301 3.59076C14.2372 3.29787 13.7623 3.29787 13.4694 3.59076L3.59076 13.4694C3.29787 13.7623 3.29787 14.2372 3.59076 14.5301L4.65898 15.5983C5.0562 15.3762 5.51416 15.2498 5.99959 15.2498C7.51837 15.2498 8.74959 16.481 8.74959 17.9998C8.74959 18.4853 8.62318 18.9432 8.4011 19.3404L9.46944 20.4088C9.76234 20.7017 10.2372 20.7017 10.5301 20.4088L20.4088 10.5301C20.7017 10.2372 20.7017 9.76234 20.4088 9.46945L19.3405 8.40117C18.9432 8.62335 18.4851 8.74983 17.9996 8.74983C16.4808 8.74983 15.2496 7.51861 15.2496 5.99983C15.2496 5.51428 15.3761 5.0562 15.5983 4.65891L14.5301 3.59076ZM12.4088 2.5301C13.2875 1.65142 14.7121 1.65142 15.5908 2.5301L16.7622 3.70158C17.273 4.21233 17.1815 4.92931 16.9239 5.36247C16.8133 5.54851 16.7496 5.76558 16.7496 5.99983C16.7496 6.69019 17.3092 7.24983 17.9996 7.24983C18.2338 7.24983 18.4509 7.18611 18.6369 7.07549C19.0701 6.81795 19.7871 6.72642 20.2978 7.23718L21.4694 8.40878C22.3481 9.28747 22.3481 10.7121 21.4694 11.5908L11.5908 21.4694C10.7121 22.3481 9.28746 22.3481 8.40878 21.4694L7.23712 20.2978C6.72643 19.7871 6.81786 19.0702 7.07533 18.6371C7.18589 18.451 7.24959 18.234 7.24959 17.9998C7.24959 17.3095 6.68994 16.7498 5.99959 16.7498C5.7654 16.7498 5.54837 16.8135 5.36237 16.9241C4.92921 17.1816 4.21233 17.273 3.70164 16.7623L2.5301 15.5908C1.65142 14.7121 1.65143 13.2875 2.5301 12.4088L12.4088 2.5301Z"
        fill="#FCFCFC"
      />
    </svg>
  </i>
  <ng-content></ng-content>
</button>
<a
  *ngIf="type === 'link'"
  nz-button
  class="button paragraph-medium regular"
  [routerLink]="link"
  [ngClass]="{
    primary: color === 'primary',
    ghost: color === 'ghost',
    tertiary: color === 'tertiary',
    'small-button': size === 'small',
    'medium-button': size === 'medium',
    'large-button': size === 'large'
  }"
>
  <i *ngIf="icon === 'wallet'" nz-icon>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M15.5 14.25C15.5 13.8358 15.8358 13.5 16.25 13.5H18.25C18.6642 13.5 19 13.8358 19 14.25C19 14.6642 18.6642 15 18.25 15H16.25C15.8358 15 15.5 14.6642 15.5 14.25ZM3 5.5H3.01373C3.13809 4.37501 4.09186 3.5 5.25 3.5H16.75C17.9926 3.5 19 4.50736 19 5.75V6.58697C20.4333 6.92555 21.5 8.21321 21.5 9.75V18.25C21.5 20.0449 20.0449 21.5 18.25 21.5H6.25C4.45508 21.5 3 20.0449 3 18.25V5.5ZM18.25 8H4.5V18.25C4.5 19.2165 5.2835 20 6.25 20H18.25C19.2165 20 20 19.2165 20 18.25V9.75C20 8.7835 19.2165 8 18.25 8ZM17.5 6.5V5.75C17.5 5.33579 17.1642 5 16.75 5H5.25C4.83579 5 4.5 5.33579 4.5 5.75C4.5 6.16421 4.83579 6.5 5.25 6.5H17.5Z"
        fill="#494F57"
      />
    </svg>
  </i>
  <i *ngIf="icon === 'share'" nz-icon>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M17 2C18.934 2 20.5018 3.56779 20.5018 5.50177C20.5018 7.43574 18.934 9.00353 17 9.00353C15.97 9.00353 15.0439 8.55887 14.4032 7.85104L9.84455 10.4555C9.94786 10.7859 10.0035 11.1374 10.0035 11.5017C10.0035 11.8663 9.94786 12.2176 9.84455 12.548L14.4023 15.1536C15.043 14.4451 15.9695 14 17 14C18.934 14 20.5018 15.5677 20.5018 17.5017C20.5018 19.4357 18.934 21.0035 17 21.0035C15.066 21.0035 13.4982 19.4357 13.4982 17.5017C13.4982 17.1374 13.5539 16.7859 13.6572 16.4555L9.09951 13.85C8.45873 14.5584 7.53223 15.0036 6.50177 15.0036C4.5678 15.0036 3 13.4358 3 11.5017C3 9.56775 4.5678 8 6.50177 8C7.53173 8 8.45784 8.44466 9.09859 9.15249L13.6572 6.54807C13.5539 6.21766 13.4982 5.86622 13.4982 5.50177C13.4982 3.56779 15.066 2 17 2ZM17 15.5C15.8945 15.5 14.9982 16.3962 14.9982 17.5017C14.9982 18.6073 15.8945 19.5035 17 19.5035C18.1055 19.5035 19.0018 18.6073 19.0018 17.5017C19.0018 16.3962 18.1055 15.5 17 15.5ZM6.50177 9.5C5.39622 9.5 4.5 10.3962 4.5 11.5017C4.5 12.6074 5.39622 13.5036 6.50177 13.5036C7.60731 13.5036 8.50354 12.6074 8.50354 11.5017C8.50354 10.3962 7.60731 9.5 6.50177 9.5ZM17 3.5C15.8945 3.5 14.9982 4.39622 14.9982 5.50177C14.9982 6.60731 15.8945 7.50353 17 7.50353C18.1055 7.50353 19.0018 6.60731 19.0018 5.50177C19.0018 4.39622 18.1055 3.5 17 3.5Z"
        fill="#494F57"
      />
    </svg>
  </i>
  <i *ngIf="icon === 'plus'" nz-icon>
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
      <path
        d="M6.8665 0.666016C6.94014 0.666016 6.99984 0.725711 6.99984 0.799349V5.99935H12.1998C12.2735 5.99935 12.3332 6.05904 12.3332 6.13268V6.86602C12.3332 6.93965 12.2735 6.99935 12.1998 6.99935H6.99984V12.1993C6.99984 12.273 6.94014 12.3327 6.8665 12.3327H6.13317C6.05953 12.3327 5.99984 12.273 5.99984 12.1993V6.99935H0.799837C0.726199 6.99935 0.666504 6.93965 0.666504 6.86602V6.13268C0.666504 6.05904 0.726199 5.99935 0.799837 5.99935H5.99984V0.799349C5.99984 0.725711 6.05953 0.666016 6.13317 0.666016H6.8665Z"
      />
    </svg>
  </i>
  <i *ngIf="icon === 'upload'" nz-icon>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M6.25 4.96466H7.40469V10.2506C7.40469 10.3193 7.46094 10.3756 7.52969 10.3756H8.46719C8.53594 10.3756 8.59219 10.3193 8.59219 10.2506V4.96466H9.75C9.85469 4.96466 9.9125 4.84435 9.84844 4.7631L8.09844 2.54747C8.08674 2.53253 8.0718 2.52045 8.05475 2.51214C8.03769 2.50383 8.01897 2.49951 8 2.49951C7.98103 2.49951 7.96231 2.50383 7.94525 2.51214C7.92819 2.52045 7.91326 2.53253 7.90156 2.54747L6.15156 4.76154C6.0875 4.84435 6.14531 4.96466 6.25 4.96466ZM13.7188 9.78185H12.7812C12.7125 9.78185 12.6562 9.8381 12.6562 9.90685V12.3131H3.34375V9.90685C3.34375 9.8381 3.2875 9.78185 3.21875 9.78185H2.28125C2.2125 9.78185 2.15625 9.8381 2.15625 9.90685V13.0006C2.15625 13.2772 2.37969 13.5006 2.65625 13.5006H13.3438C13.6203 13.5006 13.8438 13.2772 13.8438 13.0006V9.90685C13.8438 9.8381 13.7875 9.78185 13.7188 9.78185Z"
      />
    </svg>
  </i>
  <i *ngIf="icon === 'balance'" nz-icon>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M18.25 2.99609C19.7688 2.99609 21 4.22731 21 5.74609V18.2461C21 19.7649 19.7688 20.9961 18.25 20.9961H5.75C4.23122 20.9961 3 19.7649 3 18.2461V5.74609C3 4.22731 4.23122 2.99609 5.75 2.99609H18.25ZM18.25 4.49609H5.75C5.05964 4.49609 4.5 5.05574 4.5 5.74609V18.2461C4.5 18.9364 5.05964 19.4961 5.75 19.4961H18.25C18.9404 19.4961 19.5 18.9364 19.5 18.2461V5.74609C19.5 5.05574 18.9404 4.49609 18.25 4.49609ZM7.75 8.9994C8.1297 8.9994 8.44349 9.28034 8.49315 9.64485L8.5 9.74618V16.2526C8.5 16.6651 8.16421 16.9994 7.75 16.9994C7.3703 16.9994 7.05651 16.7185 7.00685 16.354L7 16.2526V9.74618C7 9.33374 7.33579 8.9994 7.75 8.9994ZM16.25 6.9994C16.6297 6.9994 16.9435 7.27381 16.9932 7.62983L17 7.72881V16.27C17 16.6728 16.6642 16.9994 16.25 16.9994C15.8703 16.9994 15.5565 16.725 15.5068 16.369L15.5 16.27V7.72881C15.5 7.32597 15.8358 6.9994 16.25 6.9994ZM11.9751 11.9961C12.3458 11.9925 12.6549 12.2628 12.707 12.6168L12.7146 12.7153L12.75 16.2626C12.754 16.6637 12.4293 16.9921 12.0249 16.9962C11.6542 16.9997 11.3451 16.7295 11.293 16.3755L11.2854 16.277L11.25 12.7296C11.246 12.3285 11.5707 12.0001 11.9751 11.9961Z"
        fill="#FCFCFC"
      />
    </svg>
  </i>
  <ng-content select="[linkText]"></ng-content>
</a>
